import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Dialog,
  DialogContent,
  Box,
} from "@mui/material";
import { StoreCard } from "../auth/firestore/getFirestoreCards";
import CardPopup from "./CardPopup";

interface MiniCardProps {
  card: StoreCard;
}

const MiniCard: React.FC<MiniCardProps> = ({ card }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <Card
        onClick={handleClickOpen}
        sx={{
          width: {
            xs: "90vw",
            md: "600px",
          },
          cursor: "pointer",
          borderRadius: "20px",
          position: "relative",
          boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.9)",
          backgroundColor: "#ffffff",
          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.02)",
          },
        }}
      >
        <CardContent
          sx={{
            padding: 0,
          }}
        >
          {/* Top box containing all the card content */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 2,
              height: "80px",
              borderBottom: "1px solid lightgrey",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
            }}
          >
            <Box>
              <Typography variant="h5">
                <strong>{card.storeName}</strong>
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {card.city}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                textAlign: "center",
                width: "20%",
              }}
            >
              <Typography variant="body2">Scans</Typography>
              <Typography variant="h4">
                <strong>
                  {card.scans}/{card.maxScans}
                </strong>
              </Typography>
            </Box>
            {card.scans >= card.maxScans && (
              <Box
                sx={{
                  position: "absolute",
                  fontSize: "8px",
                  backgroundColor: "#ffeb3b",
                  bottom: "0px",
                  right: "5px",
                  margin: "6px 9px",
                  color: "#000",
                  padding: "2px 5px",
                  borderRadius: "5px",
                  boxShadow: "0 0 10px rgba(255, 193, 7, 0.5)",
                  fontWeight: "bold",
                  zIndex: 1,
                }}
              >
                Reward Available
              </Box>
            )}
          </Box>

          {/* Bottom empty box */}
          <Box
            sx={{
              height: "3px",
              backgroundColor: "transparent",
            }}
          />
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleClose}>
        <DialogContent
          sx={{
            padding: 0,
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: "4px",
          }}
        >
          <CardPopup card={card} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MiniCard;
