import { doc, getDoc, setDoc } from "firebase/firestore";
import { auth, db } from "../../../firebaseConfig";

const getUserData = async (userID: string, user: any) => {
  try {
    const userRef = doc(db, "users", userID);
    const docSnap = await getDoc(userRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return data;
    } else {
      if (user) {
        // Check if user is authenticated
        const newUser = {
          email: auth.currentUser?.email,
          qrID: userID,
        };
        await setDoc(userRef, newUser);
        console.log("No such user in database! Document created");
        return newUser;
      } else {
        console.log("User is not authenticated, cannot create document.");
        return null;
      }
    }
  } catch (error) {
    console.error("Error fetching document:", error);
    return null;
  }
};

export default getUserData;
