import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { z as zod } from "zod";

import { authClient } from "../../lib/auth/client";
import { useUser } from "../../hooks/use-user";

import PolicyContentBox from "../../../Legals/policyContent";
import SignInWithGoogle from "../google-sign-in/google-sign-in";

// Zod schema for form validation
const schema = zod.object({
  email: zod.string().min(1, { message: "Email is required" }).email(),
  password: zod
    .string()
    .min(6, { message: "Password should be at least 6 characters" }),
  terms: zod
    .boolean()
    .refine((value) => value, "You must accept the terms and conditions"),
});

type Values = zod.infer<typeof schema>;

const defaultValues: Values = {
  email: "",
  password: "",
  terms: false,
};

export function SignUpForm(): React.JSX.Element {
  const navigate = useNavigate();
  const { checkSession } = useUser();

  const [isPending, setIsPending] = React.useState<boolean>(false);
  const [showEmailInUse, setShowEmailInUse] = React.useState<boolean>(false);
  const [openPolicy, setOpenPolicy] = React.useState<boolean>(false);
  const [policyType, setPolicyType] = React.useState<string>("");

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<Values>({ defaultValues, resolver: zodResolver(schema) });

  const onSubmit = React.useCallback(
    async (values: Values): Promise<void> => {
      setIsPending(true);

      const { error } = await authClient.signUp(values);

      if (error) {
        if (error === "Email already in use") {
          setShowEmailInUse(true);
          setTimeout(() => setShowEmailInUse(false), 3000);
        } else {
          setError("root", { type: "server", message: error });
        }
        setIsPending(false);
        return;
      }

      await checkSession?.();
      navigate("/");
    },
    [checkSession, navigate, setError]
  );

  const handleOpenPolicy = (type: string) => {
    setPolicyType(type);
    setOpenPolicy(true);
  };

  const handleClosePolicy = () => {
    setOpenPolicy(false);
    setPolicyType("");
  };

  return (
    <Card sx={{ maxWidth: 400, margin: "0 auto", mt: 5, backgroundColor: 'white' }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Sign Up
        </Typography>
        <Stack spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <FormControl error={Boolean(errors.email)} fullWidth>
                <InputLabel>Email address</InputLabel>
                <OutlinedInput {...field} label="Email address" type="email" />
                {errors.email && (
                  <FormHelperText>{errors.email.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="password"
            render={({ field }) => (
              <FormControl error={Boolean(errors.password)} fullWidth>
                <InputLabel>Password</InputLabel>
                <OutlinedInput {...field} label="Password" type="password" />
                {errors.password && (
                  <FormHelperText>{errors.password.message}</FormHelperText>
                )}
              </FormControl>
            )}
          />
          <Controller
            control={control}
            name="terms"
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} />}
                label={
                  <React.Fragment>
                    I have read and agree to the{" "}
                    <Link
                      onClick={() => handleOpenPolicy("termsAndConditions")}
                      sx={{ cursor: "pointer" }}
                    >
                      Terms
                    </Link>{" "}
                    and the{" "}
                    <Link
                      onClick={() => handleOpenPolicy("privacyPolicy")}
                      sx={{ cursor: "pointer" }}
                    >
                      Privacy Policy
                    </Link>
                    .
                  </React.Fragment>
                }
              />
            )}
          />
          {errors.terms && (
            <FormHelperText error>{errors.terms.message}</FormHelperText>
          )}
          {errors.root && <Alert severity="error">{errors.root.message}</Alert>}
          {showEmailInUse && (
            <Alert severity="error">Email already in use!</Alert>
          )}

          <Button
            disabled={isPending}
            type="submit"
            variant="contained"
            fullWidth
          >
            Sign Up
          </Button>
          <Typography variant="body2" align="center">
            Already have an account?{" "}
            <Link
              component={RouterLink}
              to="/auth/sign-in"
              underline="hover"
              variant="subtitle2"
            >
              Sign in
            </Link>
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SignInWithGoogle />
          </Box>
        </Stack>
        {openPolicy && (
          <PolicyContentBox
            open={openPolicy}
            onClose={handleClosePolicy}
            policyType={policyType}
          />
        )}
      </CardContent>
    </Card>
  );
}
