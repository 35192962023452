"use client";

import React, { useEffect, useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Collapse,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { getDynamicStoreDetails } from "../components/auth/firestore/dynamicStoreDetails";
import { useParams } from "react-router-dom";
import { UserContext } from "../contexts/user-context";
import { AddToWallet } from "../components/auth/firestore/addToWallet";

// TypeScript type for store details
interface StoreDetails {
  storeName: string;
  titleImage?: string;
  city: string;
  province: string;
  storeNumber: string;
  maxScans: number;
  reward: string;
  socialURL?: string;
  storeURL: string;
  storeID?: string;
  offers?: string[];
  disclaimer?: string;
}

interface DynamicStorePageProps {
  storeURL?: string;
}

const DynamicStorePage: React.FC<DynamicStorePageProps> = () => {
  const { storeURL } = useParams<{ storeURL: string }>();
  const context = React.useContext(UserContext);

  const [storeDetails, setStoreDetails] = useState<StoreDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [showDisclaimer, setShowDisclaimer] = useState(false);

  const [formDisabled, setFormDisabled] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("storeURL")) {
      localStorage.removeItem("storeURL");
    }
    if (storeURL) {
      getDynamicStoreDetails(storeURL)
        .then((details: any) => {
          setStoreDetails(details);
          setLoading(false);
        })
        .catch((error: Error) => {
          console.error("Error fetching store details:", error);
          setLoading(false);
        });
    }
  }, [storeURL]);

  const handleAddToWallet = async (event: React.FormEvent) => {
    event.preventDefault();
    //add to wallet function
    setFormDisabled(true);
    setFormSubmitted(true);
    await AddToWallet(
      context?.user?.uid || "",
      storeDetails?.storeID || "",
      storeDetails
    ).then(() => {
      window.location.href = "/wallet";
    });
  };

  const handleRegister = (event: React.FormEvent) => {
    event.preventDefault();
    //navigate to register
    localStorage.setItem("storeURL", storeURL || "");
    window.location.href = "/auth/sign-up";
    setFormDisabled(true);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!storeDetails) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Card sx={{ padding: 2 }}>
          <Typography variant="h6">Store not found!</Typography>
        </Card>
      </Box>
    );
  }

  return (
    <HelmetProvider>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          minHeight: "90vh",
          padding: 0,
        }}
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>{storeDetails.storeName}</title>
          <meta
            name="keywords"
            content={`stomp, stomp card, loyalty card, store, rewards, punch card, ${storeDetails.storeName}`}
          />{" "}
        </Helmet>
        <Card sx={{ maxWidth: 600, boxShadow: 3, marginBottom: 3 }}>
          {storeDetails.titleImage && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                maxWidth: "95vw",
                maxHeight: "40vh",
              }}
            >
              <CardMedia
                component="img"
                image={storeDetails.titleImage}
                alt={`${storeDetails.storeName} Image`}
                sx={{ borderRadius: "4px 4px 0 0" }}
              />
            </Box>
          )}
          <CardContent
            sx={{ paddingTop: 2, paddingBottom: 2, backgroundColor: "#faf0e6" }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h4" component="div" gutterBottom>
                {storeDetails.storeName}
              </Typography>
              {storeDetails.socialURL && (
                <a
                  href={storeDetails.socialURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <InstagramIcon
                    sx={{
                      fontSize: 30,
                      color: "#E1306C",
                      marginLeft: 1,
                      marginBottom: "5px",
                    }}
                  />
                </a>
              )}
            </Box>
            <Typography variant="body1" color="text.secondary" gutterBottom>
              Located: {storeDetails.city}, {storeDetails.province}
            </Typography>
            {storeDetails.storeNumber && (
              <Typography variant="body1" color="text.secondary" gutterBottom>
                Phone Number: {storeDetails.storeNumber}
              </Typography>
            )}
            <Typography
              variant="body1"
              color="text.secondary"
              gutterBottom
              marginBottom={"-17px"}
            >
              Reward: {storeDetails.reward}
            </Typography>
            {storeDetails.disclaimer && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "blue",
                    cursor: "pointer",
                    marginTop: 3,
                    marginBottom: 0,
                  }}
                  onClick={() => setShowDisclaimer(!showDisclaimer)}
                >
                  <Typography
                    variant="body2"
                    sx={{ textDecoration: "underline" }}
                  >
                    Terms and Conditions
                  </Typography>{" "}
                  *
                </Box>
                <Collapse in={showDisclaimer}>
                  <Box
                    sx={{
                      marginTop: 0,
                      color: "dark-grey",
                      fontSize: "0.8rem",
                    }}
                  >
                    <ul>
                      {storeDetails.disclaimer
                        .split("\n")
                        .map((point, index) => (
                          <li key={index}>{point}</li>
                        ))}
                    </ul>
                  </Box>
                </Collapse>
              </>
            )}
          </CardContent>
          <Card
            sx={{
              maxWidth: 600,
              boxShadow: 3,
              padding: 2,
              backgroundColor: "#faf0e6",
            }}
          >
            <Typography variant="h5" component="div" gutterBottom>
              Get Stomp Card
            </Typography>
            <Box>
              {" "}
              {context?.user ? (
                <Button
                  variant="contained"
                  disabled={formDisabled}
                  color="secondary"
                  fullWidth
                  sx={{ borderRadius: "7px", marginTop: 2 }}
                  onClick={handleAddToWallet} // Function to add loyalty card
                >
                  Add to Wallet
                </Button>
              ) : (
                <Button
                  variant="contained"
                  disabled={formDisabled}
                  color="error"
                  fullWidth
                  sx={{ borderRadius: "7px", marginTop: 2 }}
                  onClick={handleRegister} // Function to register and add loyalty card
                >
                  Register Wallet
                </Button>
              )}
            </Box>
            {formSubmitted && (
              <Typography
                variant="body1"
                color="text.secondary"
                sx={{ marginTop: 2 }}
              >
                Redirecting to your wallet...
              </Typography>
            )}
          </Card>
        </Card>
      </Box>
    </HelmetProvider>
  );
};

export default DynamicStorePage;
