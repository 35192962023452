import { useState } from "react";
import { Fab, Dialog, DialogContent } from "@mui/material";
import { QrCode2, Wallet } from "@mui/icons-material";
import { QrCode } from "../qrCode/qrCreator";
import { useNavigate } from "react-router-dom";

export const QrButton = () => {
  const [qrDialogOpen, setQrDialogOpen] = useState(false);

  const openQrDialog = () => setQrDialogOpen(true);
  const closeQrDialog = () => setQrDialogOpen(false);

  return (
    <div>
      <Fab
        onClick={openQrDialog}
        color="primary"
        aria-label="qrOpen"
        sx={{
          color: "inherit",
          zIndex: 10,
          backgroundColor: "#ffa5d6",
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          "&:hover": {
            backgroundColor: "#FF9CD2",
          },
        }}
      >
        <QrCode2 sx={{ fontSize: "42px" }} />
      </Fab>

      <Dialog open={qrDialogOpen} onClose={closeQrDialog}>
        <DialogContent>
          <QrCode />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export const WalletButton = () => {
  const navigate = useNavigate();
  return (
    <Fab
      aria-label="back-to-wallet"
      color="primary"
      onClick={() => navigate("/wallet")}
      sx={{
        zIndex: 1,
        backgroundColor: "#3f51b5",
        color: "#fff",
        fontSize: "1.2rem",
        boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
        "&:hover": {
          backgroundColor: "#303f9f",
        },
      }}
    >
      <Wallet sx={{ fontSize: "42px" }} />
    </Fab>
  );
};
