import * as React from "react";
import Alert from "@mui/material/Alert";
import { useUser } from "../../hooks/use-user";
import { CircularProgress } from "@mui/material";
import { Navigate } from "react-router-dom";

export interface AuthGuardProps {
  children: React.ReactNode;
}

export function GuestGuard({
  children,
}: AuthGuardProps): React.JSX.Element | null {
  const { user, error, isLoading } = useUser();
  const [isChecking, setIsChecking] = React.useState<boolean>(true);

  React.useEffect(() => {
    const checkPermissions = async () => {
      if (isLoading) return;

      if (error) {
        setIsChecking(false);
        return;
      }

      setIsChecking(false);
    };

    checkPermissions().catch(() => {
      // noop
    });
  }, [user, error, isLoading]);

  if (isChecking) {
    return <CircularProgress />;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  // Render content based on user authentication status
  if (!user) {
    return <>{children}</>;
  } else {
    return <Navigate to="/wallet" />;
  }
}
