import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Skeleton,
} from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";

interface StoreInfoCardProps {
  titleImage?: string;
  storeName: string;
  socialURL?: string;
  city: string;
  province: string;
  storeNumber?: string;
}

const StoreWalletInfoCard: React.FC<StoreInfoCardProps> = ({
  titleImage,
  storeName,
  socialURL,
  city,
  province,
  storeNumber,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  return (
    <Card sx={{ maxWidth: 600, boxShadow: 3, marginBottom: 3, marginTop: 3 }}>
      {titleImage && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "95vw",
            height: "5vh",
            position: "relative",
          }}
        >
          {!imageLoaded && (
            <Skeleton
              variant="rectangular"
              width="100%"
              height="100%"
              sx={{ position: "absolute", top: 0, left: 0 }}
            />
          )}
          <CardMedia
            component="img"
            image={titleImage}
            alt={`${storeName} Image`}
            onLoad={() => setImageLoaded(true)}
            sx={{
              borderRadius: "4px 4px 0 0",
              width: "100%",
              height: "100%",
              objectFit: "cover",
              display: imageLoaded ? "block" : "none",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        </Box>
      )}
      <CardContent
        sx={{
          paddingTop: 2,
          paddingBottom: 2,
          backgroundColor: "#faf0e6",
          minWidth: "85vw",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h4" component="div" gutterBottom>
            {storeName}
          </Typography>
          {socialURL && (
            <a href={socialURL} target="_blank" rel="noopener noreferrer">
              <InstagramIcon
                sx={{
                  fontSize: 30,
                  color: "#E1306C",
                  marginLeft: 1,
                  marginBottom: "5px",
                }}
              />
            </a>
          )}
        </Box>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Located: {city}, {province}
        </Typography>
        {storeNumber && (
          <Typography variant="body1" color="text.secondary" gutterBottom>
            Phone Number: {storeNumber}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default StoreWalletInfoCard;
