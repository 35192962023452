// SignInWithGoogle.jsx

import React from "react";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";

import { auth } from "../../../firebaseConfig";

import "./google-sign-in.css";

const SignInWithGoogle = () => {
  const handleSignInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);

      const credential = GoogleAuthProvider.credentialFromResult(result);
      // eslint-disable-next-line
      const token = credential.accessToken; // get token to interact with google api
      // eslint-disable-next-line
      const user = result.user;
      // Handle additional logic like updating state, context, or navigating to another page
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.error(errorCode, errorMessage);
      // Display error message if needed
    }
  };

  return (
    <button
      type="button"
      className="login-with-google-btn"
      onClick={handleSignInWithGoogle}
    >
      Sign in with Google
    </button>
  );
};

export default SignInWithGoogle;
