import DynamicStorePage from "./dynamicStorePage";
import { Stack } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";

export default function StoresPage() {
  const { storeURL } = useParams<{ storeURL: string }>();

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 2,
      }}
    >
      <Stack spacing={1}>
        <DynamicStorePage storeURL={storeURL} />
      </Stack>
    </Box>
  );
}
