import React, { useContext, useState } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import {
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
  useMediaQuery,
  Theme,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { getFirestoreCards } from "../auth/firestore/getFirestoreCards";
import { LogoutDialog, RefreshDialog } from "../reusables/dialogs";
import { authClient } from "../lib/auth/client";
import stompBack2 from "../../assets/stomp-back2.svg";
import { UserContext } from "../../contexts/user-context";
import StompLogo from "../../stompCardsLogo.svg";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  const context = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRefreshDialog, setOpenRefreshDialog] = useState(false);

  React.useEffect(() => {
    document.body.style.backgroundImage = `url(${stompBack2})`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "repeat-y";
    document.body.style.backgroundPosition = "center";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const userID = context?.user?.uid || "";
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up("md")
  );

  const fetchStoreCards = async (forceReset: boolean = true) => {
    if (context?.user?.uid) {
      try {
        setOpenRefreshDialog(false);
        await getFirestoreCards(userID, forceReset);
        window.location.reload();
      } catch (error) {
        console.error("Error fetching store cards: ", error);
      }
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRefresh = () => {
    setOpenRefreshDialog(true);
  };

  const cancelRefresh = () => {
    setOpenRefreshDialog(false);
  };

  const handleLogout = () => {
    setOpenDialog(true);
  };

  const confirmLogout = () => {
    handleMenuClose();
    localStorage.clear();
    setOpenDialog(false);
    authClient.signOut();
    window.location.reload();
  };

  const cancelLogout = () => {
    setOpenDialog(false);
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title>Stomp Cards: The Digital Wallet to Stomp Loyalty</title>
        <meta
          name="description"
          content="Stomp Cards is your app-less wallet to access all your Stomp Loyalty punch cards. If you prefer a traditional app layout, please install our website under settings with the add to Home Screen Button!"
        />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          padding: "10px 20px",
          backgroundColor: "rgba(33, 33, 33, 1)",
          zIndex: 100,
          boxShadow: "0 0 10px rgba(100, 100, 100, 0.2)",
        }}
      >
        {isLargeScreen ? (
          <Link to="/wallet" style={{ textDecoration: "none" }}>
            <Typography
              variant="h5"
              sx={{
                color: "white",
                fontWeight: "bold",
                letterSpacing: "1px",
              }}
            >
              Stomp Cards
            </Typography>
          </Link>
        ) : (
          <Link to="/wallet">
            <Box
              component="img"
              src={StompLogo}
              alt="Stomp Cards: Wallet"
              sx={{ height: 40, borderRadius: "50%", mb: "-4px" }}
            />
          </Link>
        )}

        {userID && (
          <div>
            <IconButton
              onClick={handleMenuOpen}
              color="inherit"
              aria-label="settings"
              sx={{
                backgroundColor: "white",
                borderRadius: "50%",
                padding: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                },
              }}
            >
              <SettingsIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{ marginTop: "2px" }}
            >
              <MenuItem>
                <Button
                  sx={{ width: "100px" }}
                  variant="contained"
                  color="success"
                  onClick={handleRefresh}
                >
                  Refresh
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  sx={{ width: "100px" }}
                  variant="contained"
                  color="error"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </MenuItem>
            </Menu>
          </div>
        )}

        <LogoutDialog
          open={openDialog}
          onCancel={cancelLogout}
          onConfirm={confirmLogout}
        />
        <RefreshDialog
          open={openRefreshDialog}
          onCancel={cancelRefresh}
          onConfirm={() => fetchStoreCards(true)}
        />
      </Box>
    </HelmetProvider>
  );
};

export default Header;
