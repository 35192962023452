import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Skeleton,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";

interface OfferCardProps {
  title: string;
  description: string;
  imageUrl: string;
}

const OfferCard: React.FC<OfferCardProps> = ({
  title,
  description,
  imageUrl,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [open, setOpen] = useState(false);

  const handleCardClick = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      <Card
        onClick={handleCardClick}
        sx={{
          display: "flex",
          borderRadius: "16px",
          overflow: "hidden",
          boxShadow: 3,
          width: { xs: "90vw", md: "600px" },
          height: "100px",
          marginY: "5px",
          cursor: "pointer",
          alignItems: "center",
        }}
      >
        {imageUrl && (
          <Box
            sx={{
              width: "100px",
              height: "100px",
              position: "relative",
              flexShrink: 0,
            }}
          >
            {!imageLoaded && (
              <Skeleton
                variant="rectangular"
                width="100%"
                height="100%"
                sx={{
                  borderRadius: "4px 0 0 4px",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              />
            )}
            <CardMedia
              component="img"
              image={imageUrl}
              alt="Offer Image"
              sx={{
                width: "100px",
                height: "100px",
                objectFit: "cover",
                position: "absolute",
                top: 0,
                left: 0,
                borderRadius: "4px 0 0 4px",
                display: imageLoaded ? "block" : "none",
              }}
              onLoad={() => setImageLoaded(true)}
            />
          </Box>
        )}

        <CardContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingRight: 2,
            maxWidth: "60%",
            alignContent: "center",
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              fontWeight: "bold",
              fontSize: { xs: "20px", md: "20px" },
              lineHeight: 1.2,
              marginBottom: "2px",
              whiteSpace: "wrap",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              lineHeight: 1.3,
              display: "-webkit-box",
              WebkitLineClamp: 2,
              WebkitBoxOrient: "vertical",
              maxHeight: "4.5em",
            }}
          >
            {title.length > 22 ? "see more details" : description}
          </Typography>
        </CardContent>
      </Card>

      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          {title}
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CardMedia
            component="img"
            image={imageUrl}
            alt="Offer Image"
            sx={{
              width: { xs: "65vw", md: "400px" },
              height: "auto",
              borderRadius: "8px",
            }}
          />
          <Typography variant="body1" sx={{ marginTop: 2 }}>
            {description}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OfferCard;
