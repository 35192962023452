import React, { useContext, useState, useEffect } from "react";
import { HelmetProvider, Helmet } from "react-helmet-async";
import {
  Typography,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Button,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";

import { getFirestoreCards } from "../auth/firestore/getFirestoreCards";
import { LogoutDialog, RefreshDialog } from "../reusables/dialogs";
import { authClient } from "../lib/auth/client";
import stompBack2 from "../../assets/stomp-back2.svg";
import { UserContext } from "../../contexts/user-context";

const Header: React.FC = () => {
  const context = useContext(UserContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [openRefreshDialog, setOpenRefreshDialog] = useState(false);

  useEffect(() => {
    document.body.style.backgroundImage = `url(${stompBack2})`;
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundRepeat = "repeat-y";
    document.body.style.backgroundPosition = "center";
    document.body.style.backgroundAttachment = "fixed";
  }, []);

  const userID = context?.user?.uid || "";

  const fetchStoreCards = async (forceReset: boolean = true) => {
    if (userID) {
      try {
        setOpenRefreshDialog(false);
        await getFirestoreCards(userID, forceReset);
        window.location.reload();
      } catch (error) {
        console.error("Error fetching store cards: ", error);
      }
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => setAnchorEl(null);

  const handleRefresh = () => setOpenRefreshDialog(true);

  const handleLogout = () => setOpenDialog(true);

  const confirmLogout = () => {
    handleMenuClose();
    localStorage.clear();
    setOpenDialog(false);
    authClient.signOut();
    window.location.reload();
  };

  const cancelLogout = () => setOpenDialog(false);

  const cancelRefresh = () => setOpenRefreshDialog(false);

  return (
    <HelmetProvider>
      <Helmet>
        <title>Stomp Cards: The Digital Wallet to Stomp Loyalty</title>
        <meta
          name="description"
          content="Stomp Cards is your app-less wallet to access all your Stomp Loyalty punch cards. If you prefer a traditional app layout, please install our website under settings with the add to Home Screen Button!"
        />
      </Helmet>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
          marginTop: "30px",
        }}
      >
        <Box
          sx={{
            outlineStyle: "solid",
            borderRadius: "12px",
            outlineWidth: "4px",
            outlineColor: "#FFA39D",
          }}
        >
          <Box
            sx={{
              outlineStyle: "double",
              borderRadius: "12px",
              outlineWidth: "10px",
              outlineColor: "#FF9B75",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.95)",
                padding: "8px 16px",
                borderRadius: "12px",
                textAlign: "center",
                fontWeight: "bold",
                letterSpacing: "1.5px",
                color: "#333",
                textShadow: "2px 2px 8px rgba(0, 0, 0, 0.3)",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                zIndex: 1,
              }}
            >
              Stomp Cards
            </Typography>
          </Box>
        </Box>

        {userID && (
          <div>
            <IconButton
              onClick={handleMenuOpen}
              color="inherit"
              aria-label="settings"
              sx={{
                position: "absolute",
                right: 20,
                top: "45%",
                transform: "translateY(-50%)",
                backgroundColor: "white",
                borderRadius: "50%",
                padding: "8px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                "&:hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.9)",
                },
              }}
            >
              <SettingsIcon />
            </IconButton>

            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
              sx={{ marginTop: "2px" }}
            >
              <MenuItem>
                <Button
                  sx={{ width: "100px" }}
                  variant="contained"
                  color="success"
                  onClick={handleRefresh}
                >
                  Refresh
                </Button>
              </MenuItem>
              <MenuItem>
                <Button
                  sx={{ width: "100px" }}
                  variant="contained"
                  color="error"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              </MenuItem>
            </Menu>
          </div>
        )}

        <LogoutDialog
          open={openDialog}
          onCancel={cancelLogout}
          onConfirm={confirmLogout}
        />
        <RefreshDialog
          open={openRefreshDialog}
          onCancel={cancelRefresh}
          onConfirm={() => fetchStoreCards(true)}
        />
      </Box>
    </HelmetProvider>
  );
};

export default Header;
