"use client";

import React, { useEffect, useState } from "react";
import {
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { getDynamicStoreDetails } from "../../../components/auth/firestore/dynamicStoreDetails";
import Offers from "./offers";
import StoreWalletInfoCard from "../storeInfo";
import { QrCode } from "../../../components/qrCode/qrCreator";
import { QrButton, WalletButton } from "../../../components/lib/fabButtons";

// TypeScript type for store details
interface StoreDetails {
  storeName: string;
  titleImage?: string;
  city: string;
  province: string;
  storeNumber: string;
  socialURL?: string;
  storeID?: string;
  offers?: Array<{ title: string; description: string; offerImage: string }>;
}

interface DynamicWalletStoreProps {
  storeURL: string;
}

const DynamicWalletStore: React.FC<DynamicWalletStoreProps> = ({
  storeURL,
}) => {
  const [storeDetails, setStoreDetails] = useState<StoreDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [qrDialogOpen, setQrDialogOpen] = useState(false);

  const closeQrDialog = () => setQrDialogOpen(false);

  useEffect(() => {
    if (storeURL) {
      getDynamicStoreDetails(storeURL)
        .then((details: StoreDetails | null) => {
          if (details) {
            setStoreDetails(details);
          } else {
            // Handle case where details is null
            setStoreDetails(null);
          }
          setLoading(false);
        })
        .catch((error: Error) => {
          console.error("Error fetching store details:", error);
          setLoading(false);
        });
    }
  }, [storeURL]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!storeDetails) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Card sx={{ padding: 2 }}>
          <Typography variant="h6">Store not found!</Typography>
        </Card>
      </Box>
    );
  }

  return (
    <HelmetProvider>
      <Box display="flex" justifyContent="center" alignItems="center">
        {" "}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            minHeight: "90vh",
            padding: 0,
          }}
        >
          <Helmet>
            <meta charSet="utf-8" />
            <title>{storeDetails.storeName}</title>
            <meta
              name="keywords"
              content={`stomp, stomp card, loyalty card, store, rewards, punch card, ${storeDetails.storeName}`}
            />
          </Helmet>

          {/* Store Info Card */}
          <StoreWalletInfoCard
            titleImage={storeDetails.titleImage}
            storeName={storeDetails.storeName}
            socialURL={storeDetails.socialURL}
            city={storeDetails.city}
            province={storeDetails.province}
            storeNumber={storeDetails.storeNumber}
          />

          {/* Offers Section */}
          <Typography
            variant="h4"
            component="div"
            gutterBottom
            sx={{ color: "white" }}
          >
            <strong>Offers</strong>
          </Typography>
          <Offers storeOffers={storeDetails.offers} />

          {/* QR Code Button */}
          <Box
            sx={{
              position: "fixed",
              bottom: (theme) => theme.spacing(5),
              right: (theme) => theme.spacing(3),
            }}
          >
            <QrButton />
          </Box>

          {/* Wallet Button */}
          <Box
            sx={{
              position: "fixed",
              bottom: (theme) => theme.spacing(5),
              left: (theme) => theme.spacing(3),
            }}
          >
            <WalletButton />
          </Box>
        </Box>
        <Dialog open={qrDialogOpen} onClose={closeQrDialog}>
          <DialogContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <QrCode />
          </DialogContent>
        </Dialog>
      </Box>
    </HelmetProvider>
  );
};

export default DynamicWalletStore;
