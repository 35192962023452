"use client";

import * as React from "react";
import { Box } from "@mui/material";
import QRCode from "react-qr-code";

export function QrCode(): React.JSX.Element {
  const qrID = localStorage?.getItem("qrID") || "";

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <QRCode size={256} value={qrID} />
    </Box>
  );
}
