import * as React from "react";
import { User as FirebaseUser, onAuthStateChanged } from "firebase/auth";
import getUserData from "../components/auth/firestore/getUserDetails";
import { auth } from "../firebaseConfig";

export interface UserContextValue {
  user: FirebaseUser | null;
  error: string | null;
  isLoading: boolean;
  checkSession?: () => Promise<void>;
  userData: any | null;
}

export const UserContext = React.createContext<UserContextValue | undefined>(
  undefined
);

export interface UserProviderProps {
  children: React.ReactNode;
}

export function UserProvider({
  children,
}: UserProviderProps): React.JSX.Element {
  const [userData, setUserData] = React.useState<any | null>(null);

  const [state, setState] = React.useState<{
    user: FirebaseUser | null;
    error: string | null;
    isLoading: boolean;
  }>({
    user: null,
    error: null,
    isLoading: true,
  });

  const checkSession = React.useCallback(async (): Promise<void> => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        setState({ user, error: null, isLoading: false });
      } else {
        // User is signed out
        setState({ user: null, error: null, isLoading: false });
      }
    });
  }, []);

  const getUserDetails = React.useCallback(async (userUID: string) => {
    localStorage.setItem("qrID", userUID);
    if (state.user) {
      const data = await getUserData(userUID, state.user); // Pass the user from the state
      if (data) {
        setUserData(data);
      }
      return data;
    }
  }, [state.user]);

  React.useEffect(() => {
    checkSession();
  }, [checkSession]);

  React.useEffect(() => {
    if (state.user) {
      getUserDetails(state.user.uid);
    }
  }, [state.user, getUserDetails]);

  return (
    <UserContext.Provider value={{ ...state, checkSession, userData }}>
      {children}
    </UserContext.Provider>
  );
}

export const UserConsumer = UserContext.Consumer;
