import React from "react";
import { Container } from "@mui/material";
import { SignUpForm } from "../components/auth/mainAuth/SignUpForm";

const SignUpPage: React.FC = () => {
  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <SignUpForm />
    </Container>
  );
};

export default SignUpPage;
