import React from "react";
import { Box, Typography } from "@mui/material";
import OfferCard from "./offerCard";

interface Offer {
  title: string;
  description: string;
  offerImage: string;
}

interface OffersProps {
  storeOffers?: Offer[];
}

const Offers: React.FC<OffersProps> = ({ storeOffers = [] }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
      }}
    >
      {storeOffers.length > 0 ? (
        storeOffers.map((offer, index) => (
          <OfferCard
            key={index}
            title={offer.title}
            description={offer.description}
            imageUrl={offer.offerImage}
          />
        ))
      ) : (
        <Typography variant="h6" color="textSecondary">
          No store offers currently available
        </Typography>
      )}
    </Box>
  );
};

export default Offers;
