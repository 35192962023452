import {
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../firebaseConfig";

export type StoreCard = {
  id: string;
  storeName: string;
  city: string;
  province: string;
  scans: number;
  maxScans: number;
  reward: string;
  titleImage: string;
  cardIcon: string;
  socialURL: string;
  storeURL: string;
  storeNumber: string;
  disclaimer: string;
  offers: string[];
};

export const getFirestoreCards = async (
  userID: string,
  forceReset?: boolean
): Promise<StoreCard[]> => {
  const storeCards: StoreCard[] = [];
  try {
    const userRef = doc(db, "users", userID);
    const userSnapshot = await getDoc(userRef);
    localStorage.setItem("qrID", userID);

    if (!userSnapshot.exists()) {
      return storeCards;
    } else {
      const userData = userSnapshot.data();
      if (userData?.refreshRequired === true || forceReset === true) {
        await updateDoc(userRef, {
          refreshRequired: false,
          qrID: userID,
        });

        const storeCollectionRef = collection(db, `users/${userID}/stores`);
        const storeSnapshot = await getDocs(storeCollectionRef);

        for (const storeDoc of storeSnapshot.docs) {
          const storeData = storeDoc.data();
          storeCards.push({
            id: storeDoc.id,
            storeName: storeData.storeName,
            city: storeData.city,
            province: storeData.province,
            maxScans: storeData.maxScans,
            reward: storeData.reward || "No reward specified",
            titleImage: storeData.titleImage || null,
            cardIcon:
              storeData.cardIcon ||
              "https://firebasestorage.googleapis.com/v0/b/stomp-loyalty.appspot.com/o/defaultImages%2FdefaultIcon.png?alt=media&token=f6249038-623a-42a5-9e70-5d53225e59c0",
            storeNumber: storeData.storeNumber,
            socialURL: storeData.socialURL || null,
            storeURL: storeData.storeURL,
            scans: storeData.scans,
            offers: storeData.offers,
            disclaimer: storeData.disclaimer || null,
          });
        }
      } else {
        console.log("Fetching data from local storage.");
        return getLocalCards();
      }
    }
    // Save storeCards array to local storage
    localStorage.setItem("storeCards", JSON.stringify(storeCards));
    return storeCards;
  } catch (error) {
    console.error("Error fetching store cards: ", error);
    return storeCards;
  }
};

// Function to retrieve data from local storage
export const getLocalCards = (): StoreCard[] => {
  const storedData = localStorage.getItem("storeCards");
  if (storedData) {
    return JSON.parse(storedData);
  }
  return [];
};
