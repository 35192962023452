"use client";

import {
  createUserWithEmailAndPassword,
  signOut as firebaseSignOut,
  updatePassword as firebaseUpdatePassword,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";

import type { User } from "../../types/user";

import { auth } from "../../../firebaseConfig";

export interface SignUpParams {
  email: string;
  password: string;
}

export interface SignInWithOAuthParams {
  provider: "google" | "discord";
}

export interface SignInWithPasswordParams {
  email: string;
  password: string;
}

export interface ResetPasswordParams {
  email: string;
}

export interface UpdatePasswordParams {
  newPassword: string;
}

class AuthClient {
  async signUp({ email, password }: SignUpParams): Promise<{ error?: string }> {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const results = userCredential.user;
      // send email verification
      await sendEmailVerification(results);
      return {};
    } catch (error) {
      if (
        error instanceof Error &&
        error.message.includes("email-already-in-use")
      ) {
        return { error: "Email already in use" };
      }
      return { error: (error as Error).message };
    }
  }

  async signInWithOAuth(_: SignInWithOAuthParams): Promise<{ error?: string }> {
    return { error: "Social authentication not implemented" };
  }

  async signInWithPassword({
    email,
    password,
  }: SignInWithPasswordParams): Promise<{ error?: string }> {
    try {
      await signInWithEmailAndPassword(auth, email, password);
      return {};
    } catch (error) {
      if (
        error instanceof Error &&
        error.message.includes("invalid-credential")
      ) {
        return { error: "Invalid Credentials." };
      }
      return { error: (error as Error).message };
    }
  }

  async resetPassword({
    email,
  }: ResetPasswordParams): Promise<{ error?: string }> {
    try {
      await sendPasswordResetEmail(auth, email);
      return {};
    } catch (error) {
      return { error: (error as Error).message };
    }
  }

  async updatePassword({
    newPassword,
  }: UpdatePasswordParams): Promise<{ error?: string }> {
    try {
      const user = auth.currentUser;
      if (user) {
        await firebaseUpdatePassword(user, newPassword);
        return {};
      } else {
        return { error: "No authenticated user found" };
      }
    } catch (error) {
      return { error: (error as Error).message };
    }
  }

  async getUser(): Promise<{ data?: User | null; error?: string }> {
    const user = auth.currentUser;

    if (!user) {
      return { data: null };
    }

    const userData: User = {
      id: user.uid,
      avatar: user.photoURL || "/assets/avatar.jpg",
      firstName: user.displayName?.split(" ")[0] || "First Name",
      lastName: user.displayName?.split(" ")[1] || "Last Name",
      email: user.email || "Email not found",
    };

    return { data: userData };
  }

  async signOut(): Promise<{ error?: string }> {
    try {
      await firebaseSignOut(auth);
      return {};
    } catch (error) {
      return { error: (error as Error).message };
    }
  }

  async getCurrentUser(): Promise<User | null> {
    return new Promise((resolve) => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          const currentUser: User = {
            id: user.uid,
            avatar: user.photoURL || "/assets/avatar.svg",
            firstName: user.displayName?.split(" ")[0] || "First Name",
            lastName: user.displayName?.split(" ")[1] || "Last Name",
            email: user.email || "Email not found",
          };
          resolve(currentUser);
        } else {
          resolve(null);
        }
        unsubscribe(); // Clean up listener once the state is checked
      });
    });
  }
}

export const authClient = new AuthClient();
