"use client";

import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { z as zod } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
  Typography,
  Stack,
  Alert,
} from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SignInWithGoogle from "../google-sign-in/google-sign-in";
import { authClient } from "../../lib/auth/client";
import { useUser } from "../../hooks/use-user";
import { Link } from "react-router-dom";

// Zod schema for form validation
const schema = zod.object({
  email: zod.string().min(1, { message: "Email is required" }).email(),
  password: zod.string().min(1, { message: "Password is required" }),
});

type SignInValues = zod.infer<typeof schema>;

const defaultValues: SignInValues = { email: "", password: "" };

const SignInForm: React.FC = () => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const { checkSession } = useUser(); // Session check

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<SignInValues>({
    defaultValues,
    resolver: zodResolver(schema),
  });

  const onSubmit = async (values: SignInValues) => {
    setIsPending(true);

    const { error } = await authClient.signInWithPassword(values);

    if (error) {
      setError("root", { type: "server", message: error });
      setIsPending(false);
      return;
    }

    // Refresh the session after successful sign-in
    await checkSession?.();
    window.location.reload();
  };

  return (
    <Card sx={{ maxWidth: 400, margin: "0 auto", mt: 5, backgroundColor: 'white' }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Sign In
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {/* Email Field */}
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <FormControl error={Boolean(errors.email)} fullWidth>
                  <InputLabel>Email address</InputLabel>
                  <OutlinedInput
                    {...field}
                    label="Email address"
                    type="email"
                  />
                  {errors.email && (
                    <FormHelperText>{errors.email.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />

            {/* Password Field */}
            <Controller
              control={control}
              name="password"
              render={({ field }) => (
                <FormControl error={Boolean(errors.password)} fullWidth>
                  <InputLabel>Password</InputLabel>
                  <OutlinedInput
                    {...field}
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      showPassword ? (
                        <VisibilityIcon
                          cursor="pointer"
                          onClick={() => setShowPassword(false)}
                        />
                      ) : (
                        <VisibilityOffIcon
                          cursor="pointer"
                          onClick={() => setShowPassword(true)}
                        />
                      )
                    }
                  />
                  {errors.password && (
                    <FormHelperText>{errors.password.message}</FormHelperText>
                  )}
                </FormControl>
              )}
            />

            {/* Error Alert */}
            {errors.root && (
              <Alert severity="error">{errors.root.message}</Alert>
            )}

            {/* Forgot Password Link */}
            <Typography color="text.secondary" variant="body2">
              Forgot Password? <Link to="/auth/reset-password">Reset</Link>
            </Typography>

            {/* Register Link */}
            <Typography color="text.secondary" variant="body2">
              Don't have an account? <Link to="/auth/sign-up">Sign up</Link>
            </Typography>

            {/* Submit Button */}
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isPending}
            >
              Sign In
            </Button>

            {/* Google Sign-In */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SignInWithGoogle />
            </Box>
          </Stack>
        </form>
      </CardContent>
    </Card>
  );
};

export default SignInForm;
