import { useState, useEffect, useCallback, useContext } from "react";
import {
  getFirestoreCards,
  StoreCard,
} from "../auth/firestore/getFirestoreCards";
import { UserContext } from "../../contexts/user-context";

export const useStoreCards = () => {
  const [storeCards, setStoreCards] = useState<StoreCard[]>([]);
  const [filteredCards, setFilteredCards] = useState<StoreCard[]>([]);
  const [loading, setLoading] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const context = useContext(UserContext);
  const userID = context?.user?.uid || null;

  const fetchStoreCards = useCallback(
    async (forceReset: boolean = false) => {
      const storedStoreCards = JSON.parse(
        localStorage.getItem("storeCards") || "[]"
      );

      if (!storedStoreCards.length) {
        forceReset = true;
      }

      if (userID) {
        try {
          const cards = await getFirestoreCards(userID, forceReset);
          setStoreCards(cards);
          setFilteredCards(cards);
          if (cards.length === 0) setSnackbarOpen(true);
        } catch (error) {
          console.error("Error fetching store cards:", error);
        } finally {
          setLoading(false);
        }
      }
    },
    [userID]
  );

  useEffect(() => {
    fetchStoreCards(); // Initial fetch without forceReset
  }, [fetchStoreCards]);

  const filterCards = (query: string) => {
    const lowerCaseQuery = query.toLowerCase();
    const filtered = storeCards.filter((card) =>
      card.storeName.toLowerCase().includes(lowerCaseQuery)
    );
    setFilteredCards(filtered);
    if (filtered.length === 0) setSnackbarOpen(true);
  };

  return {
    filteredCards,
    loading,
    filterCards,
    snackbarOpen,
    setSnackbarOpen,
    fetchStoreCards,
  };
};
