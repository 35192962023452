import * as React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import SignInPage from "./pages/signInPage";
import SignUpPage from "./pages/signUpPage";
import ResetPasswordPage from "./pages/resetPasswordPage";
import WalletPage from "./pages/walletPage";
import Header from "./components/main/header";
import { UserProvider } from "./contexts/user-context";
import { UserContext } from "./contexts/user-context";
import { Box, CircularProgress } from "@mui/material";
import { AuthGuard } from "./components/auth/guards/auth-guard";
import { GuestGuard } from "./components/auth/guards/guest-guard";
import StoresPage from "./pages/dynamicPage";
import Footer from "./components/main/footer";
import ThinHeader from "./components/main/thinHeader";

import { theme } from "./theme/theme";
import WalletStorePage from "./pages/dynamicWallet/offers/WalletStorePage";

// Define the custom theme

function App() {
  const context = React.useContext(UserContext);

  if (context?.isLoading) {
    return (
      <Box
        sx={{
          minHeight: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ position: "fixed", top: 0 }}>
          <Header />
        </Box>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <UserProvider>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            {/* Auth */}
            <Route
              path="/auth/sign-in"
              element={
                <GuestGuard>
                  <Header />
                  <SignInPage />
                </GuestGuard>
              }
            />
            <Route
              path="/auth/sign-up"
              element={
                <GuestGuard>
                  <Header />
                  <SignUpPage />
                </GuestGuard>
              }
            />
            <Route
              path="/auth/reset-password"
              element={
                <GuestGuard>
                  <Header />
                  <ResetPasswordPage />
                </GuestGuard>
              }
            />

            {/* Wallet */}
            <Route
              path="/wallet"
              element={
                <AuthGuard>
                  <ThinHeader />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <WalletPage />
                  </Box>
                </AuthGuard>
              }
            />

            <Route
              path="wallet/:storeURL"
              element={
                <AuthGuard>
                  <ThinHeader />
                  <WalletStorePage />
                </AuthGuard>
              }
            />

            <Route
              path="stores/:storeURL"
              element={
                <Box>
                  <Header />
                  <StoresPage />
                </Box>
              }
            />

            {/* Redirect to /wallet if the route doesn't match */}
            <Route path="*" element={<Navigate to="/wallet" />} />
          </Routes>
          <Footer />
        </Router>
      </ThemeProvider>
    </UserProvider>
  );
}

export default App;
