import { setDoc, doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebaseConfig";

export const AddToWallet = async (
  userID: string,
  storeID: string,
  storeData: any
) => {
  const storeRef = doc(db, "users", userID, "stores", storeID);

  const storeSnapshot = await getDoc(storeRef);
  if (storeSnapshot.exists()) {
    await setDoc(storeRef, storeData, { merge: true });
  } else {
    await setDoc(storeRef, {
      scans: 0,
      redeemed: 0,
      ...storeData,
    });
  }
  updateDoc(doc(db, "users", userID), {
    refreshRequired: true,
  });
  return;
};
